/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types'

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountAgreementPage extends Vue {
	@State('modelAccount', {namespace}) modelAccount: ModelAccountState | undefined;
	@Mutation('setModelAgreement', {namespace}) setModelAgreement: any;
	// @Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;
	@Getter('getModelAgreement', {namespace}) getModelAgreement: any;
	@Action('updateModelAgreement', {namespace}) updateModelAgreement: any;

	stateAgreement: boolean = true;

	async setUpdate() {
		this.setModelAgreement(this.stateAgreement);
		await this.updateModelAgreement({
			modelAgreement: this.stateAgreement
		})
	}

	mounted() {
		this.stateAgreement = this.getModelAgreement;
	}
}
